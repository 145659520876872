import { Button } from '@equitymultiple/react-eui';
import { yupResolver } from '@hookform/resolvers/yup';
import React from 'react';
import { useForm, useWatch } from 'react-hook-form';

import {
  Offering,
  OfferingInput,
  OmcmsClassifiedOfferings,
  useEditOfferingMutation,
} from '../../../__generated__';
import callMutationWithToastMessages from '../../../utils/callMutationWithToastMessages';
import { setDefaultValues } from '../../../utils/formHelpers';
import AssetManagementFields from './AssetManagementFields';
import OfferingAddresses from './OfferingAddresses';
import OfferingFields from './OfferingFields';
import OmcmsFields from './OmcmsFields';
import ReinvestmentFields from './ReinvestmentFields';
import SponsorFields from './SponsorFields';
import { offeringFormSchema } from './validation';

interface Props {
  offering: Offering;
  omcmsOfferings: OmcmsClassifiedOfferings;
}

const offeringToastMessages = {
  loading: 'Saving offering',
  error: 'An error occurred while updating the offering',
  success: 'Offering updated',
};

const OfferingForm: React.FC<Props> = ({ offering, omcmsOfferings }) => {
  let offeringDefaultValues;
  if (offering) {
    offeringDefaultValues = setDefaultValues(offering);
    // Remove fields from the query that don't exist on the form
    delete offeringDefaultValues.__typename;
    delete offeringDefaultValues.investorDocuments;
    delete offeringDefaultValues.omcms;

    // Delete Salesforce only field from form values
    delete offeringDefaultValues.accruedReturn;
    delete offeringDefaultValues.capitalRequest;
    delete offeringDefaultValues.currentReturn;
    delete offeringDefaultValues.dealSize;
    delete offeringDefaultValues.distributionFrequency;
    delete offeringDefaultValues.equityMultiple;
    delete offeringDefaultValues.hidden;
    delete offeringDefaultValues.irr;
    delete offeringDefaultValues.maturesOn;
    delete offeringDefaultValues.nextPaymentOn;
    delete offeringDefaultValues.offeringApproach;
    delete offeringDefaultValues.paymentStatus;
    delete offeringDefaultValues.performanceStatus;
    delete offeringDefaultValues.perpetualFund;
    delete offeringDefaultValues.rate;
    delete offeringDefaultValues.term;
    delete offeringDefaultValues.termPeriod;
    delete offeringDefaultValues.title;
    delete offeringDefaultValues.totalPreferredReturn;
    delete offeringDefaultValues.unitCost;
    delete offeringDefaultValues.uponStabilization;

    const cardImageUrl = offering?.cardImage?.url;

    offeringDefaultValues.cardImage = cardImageUrl
      ? {
          url: cardImageUrl,
          name: cardImageUrl.substring(cardImageUrl.lastIndexOf('/') + 1),
        }
      : null;
  }

  offeringDefaultValues.alternateLocation = offering.alternateLocation || '';
  offeringDefaultValues.appendBpdn = offering.appendBpdn || false;
  offeringDefaultValues.assetManagementRate =
    offering.assetManagementRate || '';
  offeringDefaultValues.closedOn = offering.closedOn || '';
  offeringDefaultValues.confidentialityRequired =
    offering.confidentialityRequired || false;
  offeringDefaultValues.confidentialityStatus =
    offering.confidentialityStatus || '';
  offeringDefaultValues.customFundingInstructions =
    offering.customFundingInstructions || '';
  offeringDefaultValues.customFundingNextSteps =
    offering.customFundingNextSteps || '';
  offeringDefaultValues.debtExtensionRate = offering.debtExtensionRate || 0;
  offeringDefaultValues.entityName = offering.entityName || '';
  offeringDefaultValues.firstTimeInvestorMinimumAmount =
    offering.firstTimeInvestorMinimumAmount || '';
  offeringDefaultValues.investmentIncrement =
    offering.investmentIncrement || '';
  offeringDefaultValues.lienPosition = offering.lienPosition || '';
  offeringDefaultValues.ltv = offering.ltv || '';
  offeringDefaultValues.originationFeeRate = offering.originationFeeRate || '';
  offeringDefaultValues.projectedAnnualReturn =
    offering.projectedAnnualReturn || '';
  offeringDefaultValues.redemptionPeriod = offering.redemptionPeriod || '';
  offeringDefaultValues.referralBonusRate = offering.referralBonusRate || '';
  offeringDefaultValues.showInvestorPacket =
    offering.showInvestorPacket || false;
  offeringDefaultValues.sortWeight = offering.sortWeight || '';

  const {
    control: offeringControl,
    getValues: getOfferingValues,
    setValue: setOfferingValue,
    handleSubmit: handleOfferingSubmit,
    formState: { errors: offeringErrors },
  } = useForm<OfferingInput>({
    mode: 'onBlur',
    resolver: yupResolver(offeringFormSchema),
    defaultValues: offeringDefaultValues,
  });

  const [editOffering, editOfferingState] = useEditOfferingMutation();

  const submittingOffering = editOfferingState.loading;

  const onOfferingSubmit = (values: OfferingInput) => {
    if (values.omcmsId === 'Disconnect') {
      values.omcmsId = null;
      values.investorPacketUrl = null;
      values.addendumDocumentUrl = null;
      values.signRequiredDocuments = [];
    }

    if (values.closedOn === '') {
      values.closedOn = null;
    }

    // Don't include card image if it hasn't changed
    const cardImageUpdated = values.cardImage instanceof File;
    if (!cardImageUpdated) delete values.cardImage;

    // Delete addresses, which are handled with separate mutations
    delete values.addresses;

    callMutationWithToastMessages(editOffering, offeringToastMessages, {
      variables: {
        offering: {
          ...values,
        },
      },
    });
  };

  return (
    <form
      onSubmit={handleOfferingSubmit(onOfferingSubmit)}
      data-testid="offeringForm"
    >
      <ReinvestmentFields
        control={offeringControl}
        errors={offeringErrors}
        offering={offering}
        useWatch={useWatch}
        getValues={getOfferingValues}
        setValue={setOfferingValue}
      />

      <OmcmsFields
        control={offeringControl}
        errors={offeringErrors}
        getValues={getOfferingValues}
        setValue={setOfferingValue}
        omcmsOfferings={omcmsOfferings}
        offering={offering}
      />

      <SponsorFields control={offeringControl} errors={offeringErrors} />

      <OfferingAddresses offering={offering} />

      <OfferingFields
        offering={offering}
        control={offeringControl}
        errors={offeringErrors}
        useWatch={useWatch}
        setValue={setOfferingValue}
      />

      <AssetManagementFields
        offering={offering}
        control={offeringControl}
        errors={offeringErrors}
        useWatch={useWatch}
      />

      <Button
        data-testid="offeringSubmitButton"
        type="submit"
        className="floatRight"
        loading={submittingOffering}
      >
        Save
      </Button>
    </form>
  );
};

export default OfferingForm;
