import {
  GetUserDocumentsQueryVariables,
  namedOperations,
  SortOrder,
  useDeleteUserMutation,
  useGetUserDocumentsQuery,
  useGetUserQuery,
  UserDocument,
  UserDocumentSortField,
  useResetPasswordMutation,
} from '__generated__';
import { Breadcrumbs, Card, Input, Table } from '@equitymultiple/react-eui';
import { capitalize } from '@mui/material';
import ConfirmationModal from 'components/ConfirmationModal/ConfirmationModal';
import { DocumentTypeDbValue } from 'constants/documents';
import useDebounce from 'hooks/useDebounce';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import Skeleton from 'react-loading-skeleton';
import { Link, useNavigate, useParams } from 'react-router-dom';
import callMutationWithToastMessages from 'utils/callMutationWithToastMessages';
import formatCurrency from 'utils/formatCurrency';
import { snakeToTitleCase } from 'utils/stringFormatting';

import { getUserStatus } from '../helpers';
import DeleteDocumentModal from './DocumentActions/DeleteDocumentModal';
import DocumentActions from './DocumentActions/DocumentActions';
import * as styles from './User.module.scss';
import UserActions from './UserActions/UserActions';

export const columnHeaders = [
  'Name',
  'Type',
  'Sub Type',
  'Date Uploaded',
  'Actions',
];

const columnHeaderToSortField = {
  Name: 'filename',
  Type: 'type',
  'Sub Type': 'documentType',
  'Date Uploaded': 'createdAt',
};

const deleteMessages = {
  loading: 'Deleting',
  error: 'An error occurred while deleting the user',
  success: 'User deleted',
};

const resetPasswordMessages = {
  loading: 'Sending the reset password instructions',
  error: 'An error occurred while sending the reset password instructions',
  success: 'Reset password instructions sent',
};

const User = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchValue, setSearchValue] = useState('');
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [sortField, setSortField] = useState<UserDocumentSortField>(null);
  const [sortOrder, setSortOrder] = useState<SortOrder>(null);
  const debouncedSearchValue: string = useDebounce<string>(searchValue, 300);
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [resetPasswordClicked, setResetPasswordClicked] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState<UserDocument | null>(
    null,
  );

  const { data, loading, error } = useGetUserQuery({
    variables: {
      userId: id,
    },
  });

  const [deleteUser, deleteUserState] = useDeleteUserMutation({
    variables: { userId: id },
    refetchQueries: [namedOperations.Query.getUsers],
  });

  const [resetPassword, resetPasswordState] = useResetPasswordMutation({
    variables: { userId: id },
  });

  const getUserDocumentsVariables: GetUserDocumentsQueryVariables = {
    id,
    filter: debouncedSearchValue,
    pagination: {
      page,
      pageSize: rowsPerPage,
    },
  };

  if (sortField && sortOrder) {
    getUserDocumentsVariables.sort = {
      field: sortField,
      order: sortOrder,
    };
  }

  const {
    data: documentsData,
    loading: documentsLoading,
    error: documentsError,
    refetch,
    fetchMore,
  } = useGetUserDocumentsQuery({
    variables: getUserDocumentsVariables,
  });

  useEffect(() => {
    if (debouncedSearchValue) refetch();
  }, [debouncedSearchValue, refetch]);

  useEffect(() => {
    refetch();
  }, [sortField, sortOrder, refetch]);

  const fetchMoreDocuments = (pageNumber: number, pageSize: number) => {
    fetchMore({
      variables: {
        pagination: {
          page: pageNumber,
          pageSize,
        },
      },
    });
  };

  const user = data?.user?.user;
  const fullName = user && `${user.firstName} ${user.lastName}`;
  const hasUserData = !loading && user;

  const documents = documentsData?.userDocuments?.documents;
  const hasDocuments = documents?.length > 0;

  useEffect(() => {
    if (error || documentsError) {
      toast.error('An error occurred while attempting to load the user');
      navigate('/users');
    }
  }, [error, documentsError, navigate]);

  const handleDeleteUser = () => {
    callMutationWithToastMessages(deleteUser, deleteMessages).then(() => {
      setDeleteClicked(false);
      deleteUserState.reset();
      navigate('/users');
    });
  };

  const handleResetPassword = () => {
    callMutationWithToastMessages(resetPassword, resetPasswordMessages).then(
      () => {
        setResetPasswordClicked(false);
        resetPasswordState.reset();
      },
    );
  };

  const handleSearchValueChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setPage(1);
    setSearchValue(event.target.value);
  };

  const handlePageChange = (
    _: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    const pageNumber = newPage + 1;
    setPage(pageNumber);
    fetchMoreDocuments(pageNumber, rowsPerPage);
  };

  const handleRowsPerPageChange: React.ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    const pageSize = parseInt(event.currentTarget.value);
    setRowsPerPage(pageSize);
    setPage(1);
    fetchMoreDocuments(1, pageSize);
  };

  const handleSortColumnChange = (
    columnIndex: number,
    newSortOrder: SortOrder,
  ) => {
    setPage(1);
    setSortField(columnHeaderToSortField[columnHeaders[columnIndex]]);
    setSortOrder(newSortOrder);
  };

  const setSelectedDocumentState = (document: UserDocument | null) => {
    setSelectedDocument(document);
  };

  const getRows = (docs) => {
    return docs.map((document) => {
      const { filename, documentType, type, createdAt, url } = document;

      const formattedSubType =
        documentType &&
        (DocumentTypeDbValue[documentType] || snakeToTitleCase(documentType));

      return {
        cells: [
          <a key="link" href={url} target="_blank" rel="noreferrer">
            {filename}
          </a>,
          type && snakeToTitleCase(type),
          formattedSubType,
          {
            value: moment.utc(new Date(createdAt)).format('M/D/YYYY'),
            sortableValue: createdAt,
          },
          <DocumentActions
            key={id}
            setSelectedDocument={setSelectedDocumentState}
            document={document as UserDocument | null}
          />,
        ],
      };
    });
  };

  return (
    <>
      {deleteClicked && (
        <ConfirmationModal
          handleCloseModal={() => setDeleteClicked(false)}
          onSubmit={() => handleDeleteUser()}
          title="Are you sure you want to delete this user?"
          content={
            <p className="textCenter">
              Deleting this user will also delete all associated investment
              accounts and documents.
            </p>
          }
          buttonText="Yes, Delete It"
          loading={deleteUserState.loading}
        />
      )}
      {resetPasswordClicked && (
        <ConfirmationModal
          handleCloseModal={() => setResetPasswordClicked(false)}
          onSubmit={() => handleResetPassword()}
          title="Are you sure you want to reset this user's password?"
          content={
            <p className="textCenter">
              This user will receive an email with the link to reset his/her
              password.
            </p>
          }
          buttonText="Reset"
          loading={resetPasswordState.loading}
        />
      )}
      <DeleteDocumentModal
        setSelectedDocumentState={setSelectedDocumentState}
        selectedDocument={selectedDocument}
      />
      <Breadcrumbs data-testid="breadcrumbs">
        <Link to="/users">Users</Link>
        {hasUserData && <span>{fullName}</span>}
      </Breadcrumbs>
      <Card data-testid="userDetails">
        <div className={styles.top}>
          <div>
            <div>
              <h2>
                {hasUserData ? (
                  fullName
                ) : (
                  <Skeleton width={200} style={{ maxWidth: '100%' }} />
                )}
              </h2>
              {hasUserData && getUserStatus(user)}
            </div>

            {hasUserData ? <span>User {user.id}</span> : <Skeleton />}
          </div>
          <div>
            <span className="textLabel">Email</span>
            {hasUserData ? (
              <span>{user.email}</span>
            ) : (
              <Skeleton width={180} style={{ maxWidth: '100%' }} />
            )}
          </div>
          <div>
            <span className="textLabel">Stage</span>
            {hasUserData ? (
              <span>{capitalize(user.investorProfile?.stage)}</span>
            ) : (
              <Skeleton width={60} style={{ maxWidth: '100%' }} />
            )}
          </div>
          <div>
            <span className="textLabel">Signed Up</span>
            {hasUserData ? (
              <span>
                {moment.utc(new Date(user.createdAt)).format('M/D/YYYY')}
              </span>
            ) : (
              <Skeleton width={80} style={{ maxWidth: '100%' }} />
            )}
          </div>
          <div>
            <span className="textLabel">Lifetime Invested</span>
            {hasUserData ? (
              <span>{formatCurrency(user.lifetimeInvested)}</span>
            ) : (
              <Skeleton />
            )}
          </div>
          {hasUserData ? (
            <UserActions
              key={user.id}
              userId={user.id as string}
              deletable={user.deletable as boolean}
              suspended={user.suspended as boolean}
              setDeleteClicked={setDeleteClicked}
              setResetPasswordClicked={setResetPasswordClicked}
              twoFactorEnabled={user.twoFactorEnabled as boolean}
              locked={user.locked as boolean}
            />
          ) : (
            <Skeleton width={60} />
          )}
        </div>
      </Card>
      <Card className={styles.documents}>
        <div className={styles.documentsTop}>
          <h3>Documents</h3>
          <Input
            id="search"
            label="Search"
            onChange={handleSearchValueChange}
            value={searchValue}
            className={styles.input}
          />
        </div>

        <Table
          data-testid="documentsTable"
          allowSorting
          sortRows={false}
          loading={documentsLoading}
          columnHeaders={columnHeaders}
          loadingRows={10}
          onSort={(columnIndex, newSortOrder) =>
            handleSortColumnChange(columnIndex, newSortOrder as SortOrder)
          }
          pagination={{
            // MUI expects 0 based index, API expects 1 based
            // MUI throws out of range error if page > 1 and there are no users
            page: hasDocuments ? page - 1 : 0,
            rowsPerPage,
            onRowsPerPageChange: handleRowsPerPageChange,
            onPageChange: handlePageChange,
            count: documentsData?.userDocuments.pageInfo?.count || 0,
          }}
          rows={
            hasDocuments
              ? getRows(documents)
              : [{ cells: ['No documents found'] }]
          }
        />
      </Card>
    </>
  );
};
export default User;
